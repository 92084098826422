import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled, { keyframes } from 'styled-components'
import { BreadCrumb, Section, Tags, ComingSoon } from '../components/Section'
import CaseStudies from "../components/CaseStudies"
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton"
import Benifits from '../components/Benifits'
import OurSolution from "../sections/service/ourSolution"
import BrandsWeTrust from "../sections/service/brandWeTrusted"
import CustomerReiview from "../sections/service/customerReview"
import Faq from "../sections/service/faq"
import LetastArticles from "../components/LetastArticles"
import GetStarted from '../components/GetStarted'
import BreakpointDown from '../components/Media/BreakpointDown'
import BreakpointUp from "../components/Media/BreakpointUp"
import { StaticImage } from "gatsby-plugin-image"
import PlusIcon from '../components/Icons/PlusIcon'
import DotteLineArrowIcon from '../components/Icons/DotteLineArrowIcon';
import SecondaryLinkButton from "../components/Button/SecondaryLinkButton"


const MonitoringSolutions = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  min-height:800px;
  padding-bottom: 44px;
  position:relative;
  flex-direction:wrap;
  ${BreakpointDown.xl` 
    padding-bottom:50px;
    min-height:auto;
  `}
  ${BreakpointDown.lg`
      padding-bottom:20px;
  `}
   ${BreakpointDown.md`
      padding-bottom:20px;
  `}
`
const MonitoringSolutionsItem = styled.div` 
  flex:0 0 50%;
  width:50%;
  padding-left:15px;
  padding-right:15px;
  position:relative;
  &:last-child{
    display:none;
   ${BreakpointUp.xl`
     padding-left:0;
     display:block;
   `}
  }
&:first-child{
  padding-right:75px;
  padding-top:210px;
  ${BreakpointDown.xl` 
    padding-top:120px;
    min-height:auto;
  `}
  ${BreakpointDown.lg`
      padding-top:120px;
  `}
   ${BreakpointDown.md`
      padding-top:90px;
  `}
  ${BreakpointDown.md`
    padding-right:15px;
  `}
}
${BreakpointDown.xl` 
  flex:0 0 100%;
  width:100%;
`}
`
const ServiceTitle = styled.h1`
  /* max-width:445px; */
  margin-top:20px;
  margin-bottom:20px;
  line-height:48px;
 ${BreakpointDown.lg`
   line-height:inherit;
 `}
`
const MonitoringSolutionsItemContent = styled.p`
font-size:16px;
`
const MonitoringSolutionsBottomWrapper = styled.div`
 position:relative;
`

const MonitoringSolutionsBottom = styled.div`
  display:flex;
  flex:0 0 100%;
  left:0;
  right:0;
  position:absolute;
  bottom:-60px;
  font-size:20px;
  font-family: 'Comic Neue', cursive;
  z-index:2;
  height:120px;
  flex-wrap:wrap;
  margin-right: 60px;
  margin-left: 60px;
  @media (min-width:2400px){
    margin-left:200px;
    margin-right:200px; 
  }
  @media (max-width: 1024px) {
    margin-right: 0;
    margin-left: 0;
  }

  @media(max-width:1280px){
    bottom:0px; 
  }
  ${BreakpointDown.xl`
    position:static;
  `}
  ${BreakpointDown.md`
    height:auto;
  `}
`
const MonitoringSolutionsBottomLeft = styled.div`
flex: 0 0 50%;
  background:#529A00;
  padding:10px 45px;
  color:#FFFFFF;
  position:relative;
  ${BreakpointDown.lg`
    padding:10px 15px;
  `}
   ${BreakpointDown.md`
    flex: 0 0 100%;
  `}
&:after{
  position:absolute;
  right:-50px;
  top:50%;
  transform:translateY(-50%);
  content:'';
  width:80px;
  height: 2px;
  margin-top: 2px;
  background-image: linear-gradient(90deg, #fff, #fff 75%, transparent 0%, transparent 100%); 
  background-size: 20px 1px;
  border: none;
  ${BreakpointDown.md`
    display:none
  `}
}
`
const InnerText = styled.div`
  display:flex;
  align-items:center;
  height: 100%;
  justify-content:flex-end;
  ${BreakpointDown.md`
      line-height: 27px;
  `}
`
const MonitoringSolutionsBottomRight = styled.div`
  flex:0 0 50%;
  padding:15px 45px;
  background:#000000;
  @media(max-width:1280px){
    flex:0 0 50%;
  }
  ${BreakpointDown.lg`
    padding:15px 15px;
  `}
  ${BreakpointDown.md`
    flex: 0 0 100%;
  `}
`
const BtnWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content:center;
.btn{
  ${BreakpointUp.md`
   margin-left:40px; 
  `}
  padding:0;
  .text{
    color:#fff;
    letter-spacing: 3.2px;
    font-size:16px;
    ${BreakpointDown.lg`
      letter-spacing: 1.1px;
    `}
    &:before{
      background: #529A00;
    }
    &:after{
      background: #fff;
    }
  }
}
`
const ImgWrapper = styled.div`
 max-width:100%;
  height:100%;
 display:block;
 position: absolute;
right:15px;
 .gatsby-image-wrapper{ width:100%; height:100%;}
`
// our services
const OurSolutionTitle = styled.div`
 
`
const HeadingWrapper = styled.div`
 display:flex;
 margin-left:-15px;
 margin-right:-15px;
 flex-wrap:wrap;
 justify-content:space-between;
`
const HeadingItem = styled.div`
  padding-left:15px;
  padding-right:15px;
`
const OurSolutionList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:40px;
 border-top:1px solid #CCCCCC;
 padding-top:40px;
 ${BreakpointDown.xl`
  padding-top:20px;
  padding-bottom:0;
  margin-top: 20px;
  text-align:center;
 `}
`
const OurSolutionListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
flex:0 0 100%;
text
 `}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
 display:none;
 `}
}
`
const OurSolutionListRight = styled.div`
flex:1;
padding-left:90px;
padding-top:20px;
${BreakpointDown.xl`
padding-left:0;
 `}
`
const OurSolutionListRightTitle = styled.div`
`
const OurSolutionListRightContent = styled.div`

`
// faq




const FadeIn = keyframes`
 from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const SectionBg = styled(Section)`
  position:relative;
  box-shadow: 0px 20px 85px #00000033;
  z-index: 2;
&:before, &:after{
  width:50%;
  ${BreakpointDown.xl`
    display:none;
    `
  }
}
.container{z-index:1}
`

const FaqTitle = styled.div`
 text-transform:uppercase;
 margin-bottom:0;
`

const FaqRow = styled.div`
 display:flex;
 flex-wrap:wrap;
 justify-content:space-around;
 align-items:center;
`
const FaqColumn = styled.div`
  flex: 0 0 50%;
  width:50%;
  position:relative;
  padding-top:150px;
  padding-bottom:150px;
  
  ${BreakpointDown.xl`
      flex: 0 0 100%;
      width:100%;
  `}
  &:first-child{
    border-right: 1px solid #00000033;
    box-shadow: 1px 0px 2px 0px #00000033;
    background:#fff;
    ${BreakpointDown.xl`
      padding-top:30px;
      padding-bottom:0px;
      border-right:0;
      box-shadow:none;
    `
  }
  }
  &:only-child{
    flex: 0 0 100%;
      width:100%;
      border-right:0;
      box-shadow:none
  }
  &:last-child{
    padding: 105px;
    ${BreakpointDown.xl`
        padding: 30px 0px;
      `
  }
   &:before{
     content:"?";
     font-size:800px;
     font-weight:900;
      position:absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
     font-family: "Nunito Sans", sans-serif;
     text-transform:uppercase;
     letter-spacing: -8px;
     color: #000000;
     opacity: 0.05;
     display:none;
     ${BreakpointDown.xl`
       display:none;
    `
  }
   }
  }
`
const TabList = styled.div` 
`
const TabListDesktop = styled(TabList)`
display:none;
${BreakpointUp.xl`
   display:inherit
`
  }
`
const TabListMobile = styled(TabList)`
display:none;
${BreakpointDown.xl`
   display:inherit
   `
  }
`
const TabListItem = styled.div`
padding-top:20px;
padding-bottom:20px;
display:flex;
justify-content:space-between;
align-items:center;
padding-left:20px;
padding-right:20px;
margin-bottom:0;
position: relative;
font-family: "Nunito Sans", sans-serif;
line-height: 24px;
font-weight: bold;
cursor: pointer;
${BreakpointDown.xl`
  flex-direction:column;
  background:#fff;
  border-bottom: 1px solid #00000033;
  padding-left:15px;
padding-right:15px;
 &:nth-of-type(){
  border-top: 1px solid #00000033;
 }
 `}
 ${BreakpointDown.md`
  padding-right:35px;
 `}

svg{
  transition: all 0.1s linear;
  position:absolute;
  right:0;
  ${BreakpointDown.md`
    right:-15px;
 `}
}
&:before{
    content:'';
    background:#F8F8F8;
    position:absolute;
    left:0;
    right:-40px;
    top:0;
    bottom:0;
    display:none;
    ${BreakpointDown.xl`
     right:0;
    `}
  }
&+&{
  border-top: 1px solid #00000033;
}
&.active{
  >div{
    color:#000;
  }
  svg{
    transform: rotate(45deg);
    path{
      fill:#000;
    }
  }
  .icon{
     display:block;
     ${BreakpointDown.xl`
    display:none;
    `}
  
  }
&:before{
  display:block;
}
}
`
const Text = styled.div`
  position:relative;
  z-index:1;
  display:flex;
  justify-content:space-between;
  align-items:center;
  width: 100%;
  color:#666666;
`
const Icon = styled.div`
  position:absolute;
  right:-30px;
  display:none;
  margin-top: 8px;
  transform: rotate(224deg);

 svg{
   margin-left:15px;
   path{
     fill:#000;
   }
 }
`
const TabContnet = styled.div`
  position:relative;
  z-index:1;
 
`
const TabContentItem = styled.div`
  animation-duration: 1s;
  animation-name: ${FadeIn};
  animation-fill-mode: both;
  transition: opacity 0.15s linear;
  &.tab-item{
    ${BreakpointUp.xl`
    display:none;
    `
  }
    &.active{
      ${BreakpointUp.xl`
        display:inherit;
        `
  }
    .tab-content-inner{
      padding-top:20px;
      height:auto;
      overflow:auto;
    }
    }
  }
`
const TabContentinner = styled.div`
   ${BreakpointDown.xl`
      height:0;
      overflow:hidden;
      transition: height 0.15s linear; 
    `
  }
`
//Letast BLogs


const Card = styled.div`
  padding: 70px 50px ;
  background:#fff;
  box-shadow: 0px 20px 45px rgb(0, 0, 0, 0.20);
  /* @media (min-width:2200px){
    padding: 20px 15px;
  } */
  @media(max-width:1440px){
    padding: 20px 14px; 
  }
  ${BreakpointDown.lg`
   text-align:center;
 `}
  .btn{
    margin-top:15px;
  }
`
const CardTitle = styled.div`
 margin-bottom:6px;
 font-weight:bold;
`
const CardImg = styled.div`
  position: relative;
  max-width:100%;
  width:100%;
  margin-top:26px;
  .gatsby-image-wrapper{
    width:100%;
  }
`
const Date = styled.div`
  color:#666666;
  font-size:10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  @media (min-width:2200px){
    font-size:14px;
  }
`
const SectionHeader = styled.div`
 text-align:center;
 .h1{ margin-bottom:0px;}
 ${BreakpointDown.md`
 p{
   margin-bottom:0;
 } 
 `}
`
const Title = styled.h2`

`

const Blogs = styled.div`
 display: flex;
 margin-inline:-15px;
 flex-wrap:wrap;
 margin-top:50px;
`
const BlogItems = styled.div`
 flex:0 0 auto;
 width:33.3333%;
 padding-inline:15px;
 @media(max-width:767px){
   width:100%;
  & + &{
    margin-top:20px;
  }
 }
`

function ServicePage() {
  const [activeTab, setactiveTab] = useState(0);
  const HandleTabs = (key) => {
    setactiveTab(key);
  };
  return (
    <Layout pageName="service">
      <SEO title="WYN Technologies - providing storage, inventory and depot repair services
      "  description="Looking for staging and warehousing services? Reduce your Cost with WYN Technologies' reliable and professional, end to end warehousing, and depot repair services.
"/>
      <Section bgColor="#F8F8F8" pt="0" pb="0">
        <div className="container">
          <MonitoringSolutions>
            <MonitoringSolutionsItem>
              <BreadCrumb>
                <Link to="/">Home</Link><Link to="/">Services</Link><Link to="/#">Staging & Configuration of IT Hardware & Peripherals</Link>
              </BreadCrumb>
              <ServiceTitle>Staging & Configuration of IT Hardware & Peripherals</ServiceTitle>
              <MonitoringSolutionsItemContent>
                <p>When it comes to meeting your business technology needs, <strong>WYN</strong> is here to listen. And after we gather a clear understanding of your wants and goals, we’ll provide you with an optimal IT solution for your business. And because it’s crucial to get your new technology up and running smoothly, we’ll stage and configure all your new devices at our controlled facility beforehand. 
                </p>
                <p>A key advantage to this approach is that it makes your installation go much more smoothly, with fewer unanticipated hiccups. You can trust the quality of our hardware configuration services. We don’t just help you manage your equipment, we help you to maximize it!</p>
              </MonitoringSolutionsItemContent>
            </MonitoringSolutionsItem>
            <MonitoringSolutionsItem>
            <ImgWrapper>
            <StaticImage
                src="../images/staging-storage-inventory.jpg" 
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Letast Article"
                placeholder="blurred"
                />
              </ImgWrapper>
              </MonitoringSolutionsItem>
          </MonitoringSolutions>
        </div>
      </Section>
      <MonitoringSolutionsBottomWrapper>
        <div className="container">
          <MonitoringSolutionsBottom>
            <MonitoringSolutionsBottomLeft>
              <InnerText>We ensure that you get the right technologies and best products</InnerText>
            </MonitoringSolutionsBottomLeft>
            <MonitoringSolutionsBottomRight>
              <BtnWrapper>
                <PrimaryLinkButton text="Solution You’re Looking!" />
              </BtnWrapper>
            </MonitoringSolutionsBottomRight>
          </MonitoringSolutionsBottom>
        </div>
      </MonitoringSolutionsBottomWrapper>
      <Benifits />
      <Section bgColor="#F8F8F8" pt="90px" pb="90px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <HeadingWrapper>
            <HeadingItem>
              <OurSolutionTitle className="h1">What We Provide with Our Computer Hardware Configuration & IT Network Installation Services</OurSolutionTitle>
              {/* <p>Lorem Ipsum is simply dummy text of the printing and type setting industry.</p> */}
            </HeadingItem>
          </HeadingWrapper>

          <OurSolutionList>
            <OurSolutionListLeft>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </OurSolutionListLeft>
            <OurSolutionListRight>
              <OurSolutionListRightTitle className="h3">Installation of Hardware & Technical Consultation</OurSolutionListRightTitle>
              <OurSolutionListRightContent><p><strong>WYN</strong> is a preferred dealer of top IT hardware solutions for businesses, but that’s only the beginning. We do much more than simply make great products available. We can design the perfect custom IT hardware, peripherals, & network system for your individual business, and we can get it all installed for you. All of our clients can testify to the fact that our level of install workmanship is second to none! But even then, we’re still not done. We’ll take the time to walk you through how everything works, and we’ll be patient in answering any questions you may have.</p>
              </OurSolutionListRightContent>
            </OurSolutionListRight>
          </OurSolutionList>
          <OurSolutionList>
            <OurSolutionListLeft>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </OurSolutionListLeft>
            <OurSolutionListRight>
              <OurSolutionListRightTitle className="h3">Comprehensive Staging Capabilities</OurSolutionListRightTitle>
              <OurSolutionListRightContent><p>It’s what <strong>WYN</strong> Technologies does before the day of installation which helps to set us apart, as well. We have our own climate-controlled, 5,000-square-foot data facility, which is equipped with 24 individual staging tables. On these staging tables, we actually have the ability to set up and test up to 240 unique machines! When it comes to staging services, nobody does it better! Our facility is also equipped with a secure data closet, limited access control, and a state-of-the-art surveillance system. In other words, you can trust that your equipment will remain safe and protected while it’s in our care. No need to search for “staging companies near me,” just give the pros at <strong>WYN</strong> a call!</p></OurSolutionListRightContent>
            </OurSolutionListRight>
          </OurSolutionList>
        </div>
      </Section>
      <BrandsWeTrust />
      <CustomerReiview />
      <CaseStudies />
      <GetStarted />
      <SectionBg bgBefore="#fff" bgAfter="#fff">
        <div className="container">
          <FaqRow>
            <FaqColumn>
              <FaqTitle className="h1">faq</FaqTitle>

              <TabListDesktop>
                {[
                  {
                    title: 'What is computer hardware, exactly?',
                  },
                  {
                    title: 'What’s the difference between internal hardware and external peripherals?',
                  },
                  {
                    title: 'What are some examples of peripherals?',
                  },
                  {
                    title: 'What does it mean to configure your IT hardware?',
                  },
                  {
                    title: 'What does hardware staging involve?',
                  },
                  {
                    title: 'What are the advantages of going through staging before installation?',

                  }
                ].map((item, index) => (<TabListItem key={index} onClick={() => HandleTabs(index)} className={`${activeTab === index ? "active" : ""}`}><Text>{item.title} <PlusIcon /> <Icon className="icon"><DotteLineArrowIcon /></Icon></Text>
                </TabListItem>
                ))}
              </TabListDesktop>

            </FaqColumn>
            <FaqColumn>
              <TabContnet>
                {[
                  {
                    title: 'What is computer hardware, exactly?',
                    content: 'In a basic sense, computer hardware simply refers to the essential physical components which make up a computer system. Some types of hardware are installed inside the computer, while other types can be connected externally. The more common internal hardware components include things like the motherboard, CPU, RAM, hard drive or solid-state drive, video card, SD card reader, and power supply.'
                  },
                  {
                    title: 'What’s the difference between internal hardware and external peripherals?',
                    content: `It’s really just that. Internal hardware is located inside the main computer shell or tower itself, while external peripherals are located outside of the main computer housing. Peripherals can be connected to the system by direct cable, or they can be part of a wireless network of connected devices.
                    `
                  },
                  {
                    title: 'What are some examples of peripherals?',
                    content: 'Peripherals are generally defined as the external components of a computer system which are designed for specific purposes and applications. Universally common peripherals can include things like a monitor, keyboard, mouse, flash drive, external hard drive, pen tablet, printer, and speakers. Peripherals that are more business-specific can include things like barcode scanners, document scanners, flatbed scanners, laser printers, touchscreen monitors, graphics tablets, and business projectors.'
                  },
                  {
                    title: 'What does it mean to configure your IT hardware?',
                    content: 'Hardware configuration has to do with the details and specific system resource allocations for each specific connected device. IT specialists will look to improve hardware performance by tweaking the settings for things like the motherboard, BIOS, bus speeds, etc. Most plug-and-play devices will auto-configure such settings, but many of these can be customized by IT specialists as well.'
                  },
                  {
                    title: 'What does hardware staging involve?',
                    content: 'Staging involves the preliminary configuration of your hardware in a dedicated facility in advance of its installation in your specific facility. This includes things like tweaking hardware settings for better performance, setting up firewalls and access points, configuring servers and peripherals, and the like.'
                  },
                  {
                    title: 'What are the advantages of going through staging before installation?',
                    content: 'Staging and configuring your equipment ahead of time will make your hardware installation go much faster and smoother. It serves to get your installs and related projects up and running more quickly, thus saving you valuable time and resources. At <strong>WYN Technologies</strong>, one of our key service pillars is to free you up from tedious, unnecessary tasks so you can give greater attention to running your business and focusing on your own important business priorities, important tasks,  and key initiatives. Got questions about our staging and configuration services? Just get in touch with us today!'
                  }
                ].map((item, index) => {
                  return (
                    <TabContentItem key={index} className={activeTab === index ? 'tab-item active' : 'tab-item'} >
                      <TabListMobile> <TabListItem key={index} onClick={() => HandleTabs(index)} className={`${activeTab === index ? "active" : ""}`}><Text>{item.title}<PlusIcon /> <Icon className="icon"><DotteLineArrowIcon /></Icon></Text>
                      </TabListItem></TabListMobile>
                      <TabContentinner className="tab-content-inner">
                        <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                      </TabContentinner>
                    </TabContentItem>
                  )
                })}
              </TabContnet>
            </FaqColumn>
          </FaqRow>
        </div>
      </SectionBg>
      <Section pt="80px" mpt="40px" bgColor="#F8F8F8" pb="50px" mpb="40px" xxpt="100px" xxpb="100px">
        <div className="container">
          <SectionHeader>
            <Title className="h1">Latest Articles & Blogs</Title>
          </SectionHeader>
          <ComingSoon textAlign="center">Coming Soon...</ComingSoon>
          {/* <Blogs>
            <BlogItems>
              <Card>
                <Date>24 June, 2020</Date>
                <CardTitle className="h6">We were recently featured on the local WXII news channel with regard to Thermal Temperature Monitoring.</CardTitle>
                <Tags to="/">Surveillance System</Tags> <Tags to="/">Thermal Monitoring</Tags>
                <CardImg>
                  <StaticImage
                    src="../../images/article-1.jpg"
                    quality={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Letast Article"
                    placeholder="blurred"
                  />
                </CardImg>
                <Link to="/"><SecondaryLinkButton text="Learn More" /></Link>
              </Card>
            </BlogItems>
            <BlogItems>
              <Card>
                <Date>24 June, 2020</Date>
                <CardTitle className="h6">We were recently featured on the local WXII news channel with regard to Thermal Temperature Monitoring.</CardTitle>
                <Tags to="/">Surveillance System</Tags> <Tags to="/">Thermal Monitoring</Tags>
                <CardImg>
                  <StaticImage
                    src="../../images/article-1.jpg"
                    quality={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Letast Article"
                    placeholder="blurred"
                  />
                </CardImg>
                <Link to="/"><SecondaryLinkButton text="Learn More" /></Link>
              </Card>
            </BlogItems>
            <BlogItems>
              <Card>
                <Date>24 June, 2020</Date>
                <CardTitle className="h6">We were recently featured on the local WXII news channel with regard to Thermal Temperature Monitoring.</CardTitle>
                <Tags to="/">Surveillance System</Tags> <Tags to="/">Thermal Monitoring</Tags>
                <CardImg>
                  <StaticImage
                    src="../../images/article-1.jpg"
                    quality={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Letast Article"
                    placeholder="blurred"
                  />
                </CardImg>
                <Link to="/"><SecondaryLinkButton text="Learn More" /></Link>
              </Card>
            </BlogItems>
          </Blogs> */}
        </div>
      </Section>
    </Layout>
  )
}

export default ServicePage
